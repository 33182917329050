import Swiper from '../vendors/swiper.min.js';

export default class Sliders {
  static mainSlider() {
    return new Swiper('.keyvisual-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
    });
  }
  static futures01Slider() {
    return new Swiper('.futures01-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade'
    });
  }
  static futures02Slider() {
    return new Swiper('.futures02-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade'
    });
  }
  static futures03Slider() {
    return new Swiper('.futures03-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade'
    });
  }



  static pointsSlider() {
    return new Swiper('.futures-02__slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 4,
      breakpoints: {
        767: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 1,
        },
      },
    });
  }


  static bathSlider() {
    return new Swiper('.bath-slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.bath-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }

   static roomsSlider() {
    return new Swiper('.futures-04__large-image', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
    });
  }

  static historySlider() {
    return new Swiper('.history__slider', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 3,
      breakpoints: {
        767: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 1,
        },
      },
    });
  }
  static facilitiesSlider() {
    return new Swiper(".facilities__slider", {
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 10,
      centeredSlides : true,
      autoplay: 3000
    });
  }

  static banquetSlider() {
    return new Swiper(".banquet__slider", {
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 10,
      centeredSlides : true,
      autoplay: 3000
    });
  }
  static historySlider() {
    return new Swiper(".history__slider", {
      loop: true,
      slidesPerView: 1.5,
      spaceBetween: 10,
      centeredSlides : true,
      autoplay: 3000
    });
  }
  static educationSlider() {
    return new Swiper(".education__slider", {
      loop: true,
      loopAdditionalSlides: 3,
      slidesPerView: 1.5,
      spaceBetween: 10,
      centeredSlides : true,
      autoplay: 3000
    });
  }

  static cuisine01Slider() {
    return new Swiper('.cuisine_slider01', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }
  static cuisine02Slider() {
    return new Swiper('.cuisine_slider02', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }
  static cuisine03Slider() {
    return new Swiper('.cuisine_slider03', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }
  static cuisine04Slider() {
    return new Swiper('.cuisine_slider04', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }

  static banquet01Slider() {
    return new Swiper('.banquet_slider01', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }
  static banquet02Slider() {
    return new Swiper('.banquet_slider02', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade'
    });
  }

  static facilities01Slider() {
    return new Swiper('.facilities_slider01', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      navigation: {
        nextEl: '.facilities_slider_next',
        prevEl: '.facilities_slider_prev',
      },
      effect: 'fade'
    });
  }

  static facilities02Slider() {
    return new Swiper('.facilities_slider02', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      navigation: {
        nextEl: '.facilities_slider_next',
        prevEl: '.facilities_slider_prev',
      },
      effect: 'fade'
    });
  }

  static planSlider() {
    var planswiper_a;
    $(window).on('load resize', function(){
        var w = $(window).width();
        if (w <= 767) {
            if (planswiper_a) {
                return;
            } else {
              var planswiper_a = new Swiper('.plan__slider__a', {
                observer: true,
                observeParents: true,
                loop: false,
                slidesPerView: 1,
                centeredSlides : true,
                autoplay: 3000,
                navigation: {
                  nextEl: '.plan_next',
                  prevEl: '.plan_prev',
                },
              });
              var planswiper_b = new Swiper('.plan__slider__b', {
                observer: true,
                observeParents: true,
                loop: false,
                slidesPerView: 1,
                centeredSlides : true,
                autoplay: 3000,
                navigation: {
                  nextEl: '.plan_next',
                  prevEl: '.plan_prev',
                },
              });
              var planswiper_c = new Swiper('.plan__slider__c', {
                observer: true,
                observeParents: true,
                loop: false,
                slidesPerView: 1,
                centeredSlides : true,
                autoplay: 3000,
                navigation: {
                  nextEl: '.plan_next',
                  prevEl: '.plan_prev',
                },
              });
            }
        } else {
            if (planswiper_a) {
              planswiper_a.destroy();
              planswiper_b.destroy();
              planswiper_c.destroy();
              planswiper_a = undefined;
              planswiper_b = undefined;
              planswiper_c = undefined;
            }
        }
    });
  }
}

import throttle from 'lodash/throttle';

export default class Animations {
  constructor() {
    this.blockList = Array.from(document.querySelectorAll('[data-animation]'));
    this.threshold = 1.3;

    this.onScroll = this.onScroll.bind(this);

    this.bindEvents();
    this.onScroll();
  }

  bindEvents() {
    window.addEventListener('scroll', throttle(this.onScroll, 16));
  }

  onScroll() {
    this.blockList.forEach(el => {
      let isInView = el.getBoundingClientRect().top <= window.innerHeight / this.threshold;
      if (!isInView) return;

      el.classList.add('is-animation-over');
      this.blockList.splice(this.blockList.indexOf(el), 1);
    })

    if (window.pageYOffset > 0) {
      document.querySelector('.header').classList.add('is-scrolled');
    } else {
      document.querySelector('.header').classList.remove('is-scrolled');
    }
  }
}

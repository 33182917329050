import Search from './Search';

class YoyakuBan extends Search {
  constructor({formName, newWindow, hotelName, maxNights} = {}) {
    super(formName, newWindow);
    this.formName = formName;
    this.forms = document.getElementsByName(formName);
    this.newWindow = newWindow;
    this.hotelName = hotelName;
    this.maxNights = maxNights;
    this.initialize();
  }

  initialize() {
    this.appendOptions('nights', this.maxNights);
    this.appendOptions('rooms', 10);
    this.bindEvents();
  }

  executeSearch(e) {
    e.preventDefault();
    let currentForm = e.currentTarget.parentNode;
    this.checkInDate = currentForm.checkin.value;
    this.nights = currentForm.nights.value;
    this.rooms = currentForm.rooms.value;
    this.openResult(this.createUri());
  }

  createUri() {
    let uri = 'https://reserve.489ban.net/client/' + this.hotelName + '/0/plan/search';
    uri += '?date_ym=' + this.checkInYear + '-' + this.zeroPadding(this.checkInMonth, 2);
    uri += '&date_d=' + this.zeroPadding(this.checkInDay, 2);
    uri += '&date=' + this.checkInYear + '-' + this.zeroPadding(this.checkInMonth, 2) + '-' + this.zeroPadding(this.checkInDay, 2);
    uri += '&numberOfNights=' + this.nights;
    uri += '&roomCount=' + this.rooms;
    return uri;
  }
}
export default YoyakuBan;

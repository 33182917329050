import '../styles/main.scss';
import smoothscroll from 'smoothscroll-polyfill';
import Util from './modules/Utilities';
import Menu from './modules/Menu';
import Sliders from './modules/Sliders';
import Animations from './modules/Animations';
import Calendar from './search/Calendar';
import Search from './search/YoyakuBan';

document.addEventListener('DOMContentLoaded', () => {
  Util.forEachPolyfill();
  smoothscroll.polyfill();
  Util.anchorsInit();
  Util.addTargetBlank();

  Sliders.mainSlider();
  Sliders.futures01Slider();
  Sliders.futures02Slider();
  Sliders.futures03Slider();
  Sliders.pointsSlider();
  Sliders.bathSlider();
  Sliders.bathSlider();
  Sliders.roomsSlider();
  Sliders.facilitiesSlider();
  Sliders.banquetSlider();
  Sliders.historySlider();
  Sliders.educationSlider();
  Sliders.planSlider();
  Sliders.cuisine01Slider();
  Sliders.cuisine02Slider();
  Sliders.cuisine03Slider();
  Sliders.cuisine04Slider();
  Sliders.banquet01Slider();
  Sliders.banquet02Slider();
  Sliders.facilities01Slider();
  Sliders.facilities02Slider();


  new Animations();
  new Menu(document.querySelector('.menu'));

  /**
   * 空室検索フォーム
   */
  new Calendar(document.querySelector('#date'));
  new Search({
    formName: 'search',
    newWindow: true,
    hotelName: 'honnoji',
    maxNights: 7,
  });
})

/**
 * スムーススクロール
 */
$(function(){
   $('a[href*="#"]').click(function() {
     var adjust = -85;
      var speed = 500;
      var href= $(this).attr("href");
      var check=href.indexOf("#");
      var check=String(check);
      var text = href.substring(check);
      var target = $(text);
      var position = target.offset().top + adjust;;
      $("body,html").animate({scrollTop:position}, speed, 'swing');
      return false;
   });
});

/**
 * 言語ボタン
 */
$(function(){
  $('.header-language__toggle').click(function() {
    $('.header-language__menu').toggleClass('is-active');
  });
});

export default class Menu {
  constructor(elm) {
    this.elm = elm;
    this.desktopButton = document.querySelector('.header-toggle');
    this.mobileButton = document.querySelector('.mobile-navigation__toggle');
    this.anchors = this.elm.querySelectorAll('.anchor');
    this.phoen = document.querySelector('.header-phone');
    this.sns = document.querySelector('.header-sns');
    this.logo = document.querySelector('.header-logo');

    this.onToggleClick = this.onToggleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.bindEvents();
  }

  bindEvents() {
    this.desktopButton.addEventListener('click', this.onToggleClick);
    this.mobileButton.addEventListener('click', this.onToggleClick);
    this.anchors.forEach(el => el.addEventListener('click', this.closeMenu));
  }

  onToggleClick(e) {
    e.preventDefault();
    this.elm.classList.toggle('is-opened');
    this.desktopButton.classList.toggle('is-active');
    this.mobileButton.classList.toggle('is-active');
    this.phoen.classList.toggle('is-invisible');
    this.sns.classList.toggle('is-invisible');
    this.logo.classList.toggle('is-invisible');
  }

  closeMenu(e) {
    e.preventDefault();
    this.elm.classList.remove('is-opened');
    this.desktopButton.classList.remove('is-active');
    this.mobileButton.classList.remove('is-active');
    this.phoen.classList.remove('is-invisible');
    this.sns.classList.remove('is-invisible');
    this.logo.classList.remove('is-invisible');
  }
}
